#geocoder {
  position: absolute;
  z-index: 2;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  // right: calc(~"2rem + 1rem");
}

.tool.geocoder {
  max-width: 20rem;

  .tool-body.adress {
    width: 20rem;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;

    input,
    button {
      padding: 0.5rem 1rem;
      height: 2rem;
      background-color: white;
      border: @blueBorder;
      border-width: 2px;
    }

    input {
      flex: 1;
      border-right: 0px;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    button {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      display: flex;
      align-items: center;
      color: @blue;
      font-weight: bold;

      &:hover {
        background-color: @blue;
        color: white;
      }
    }
  }
}

.tool-body.results {
  position: relative;
  background-color: white;
  border: @blueBorder;
  padding: 0.5rem;
  padding-top: 1.5rem;
  margin-top: -1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  z-index: 1;

  .adress-result {
    cursor: default;
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
    &:hover {
      text-decoration: underline;
    }
  }
}

#feature-info {
  position: absolute;
  right: 0.5rem;
  top: 3rem;
  border: @blueBorder;
  border-radius: 0.5rem;
  background-color: white;
  width: 20rem;
  padding: 0.5rem;
  z-index: 2;

  .count {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;

    .color-circle {
      flex-shrink: 0;
      display: block;
      margin-right: 0.5rem;
      width: @legend-symbolW;
      height: @legend-symbolW;
      border-radius: @legend-symbolW;
      stroke: 1px;
    }

    .count-number {
      margin-right: 0.5ch;
    }
  }

  .type-description, .description {
    font-size: 0.8rem;
    padding-left: calc(@legend-symbolW + @margin*0.5 + 0.2rem);
    padding-right: 0.8rem;
  }
  .description {
    padding-left: calc(@legend-symbolW + 0.8rem + @margin*0.5);
  }

  button {
    margin-right: auto;
    margin-top: 1rem;
  }
}

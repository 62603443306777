h1 {
  display: flex;
  align-items: center;
  padding: 0;
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
  text-transform: initial;
  font-weight: bold;
  color: @blue;
  line-height: 0.9;
  border-bottom: @blueBorder;

  display: flex;
  align-items: flex-start;
  gap: 0.5rem;

  .logo {
    background: url("assets/logo.png") no-repeat center;
    background-size: contain;
    background-position: left;
    width: 3.5rem;
    height: 2rem;
    flex-shrink: 0;
  }
}

* {
  .scroll-mixin();
  box-sizing: border-box;
}

html {
  font-size: 20px;
}

body {
  font-family: "Calibri", sans-serif;
  margin: 0px;
  color: @black;
}

a {
  color: inherit;
  font-weight: bold;
  //   font-family: "Reglo", sans-serif;
}

button.more {
  background-color: white;
  color: @blue;
  font-weight: bold;
  border: 2px solid @blue;
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;

  &:hover {
    background-color: @blue;
    color: white;
  }
}

#map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: @blueBorder;
}

.mobile-header {
  display: none;
}

.lang-switch {
  position: absolute;
  top: 0.5rem;
  right: 21rem;
  z-index: 2;
  background-color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: @blueBorder;
  border-width: 2px;
  cursor: default;
  color: @blue;

  a {
    text-decoration: none;
  }

  &:hover {
    background-color: @blue;
    color: white;
  }
}

.credits {
  position: absolute;
  bottom: 0;
  left: 0.5rem;
  font-size: 0.7rem;
  color: darken(@greyMedium, 10%);
  mix-blend-mode: multiply;

  p {
    margin: 0;
  }

  a {
    font-weight: initial;
  }
}

h1,
h2,
h3 {
  //   font-family: "SpaceGrotesk", sans-serif;
  color: @blue;
  font-weight: 100;
  margin: 0;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.65rem;
}

h3 {
  font-size: 1.5rem;
  font-variant: small-caps;
  letter-spacing: -0.03rem;
  margin-bottom: 0.5rem;
}

.disclaimer {
  margin-top: @margin * 2;
  font-size: 1rem;
  // font-style: italic;
  max-width: 35em;
}

// .kv {
//   display: flex;
//   border-top: @border;

//   &:last-child {
//     border-bottom: @border;
//   }

//   > div {
//     padding: 3px;
//     flex: 1;
//   }

//   .key {
//     // font-family: "Reglo", sans-serif;
//   }

//   &.Remarque {
//     color: grey;
//     font-style: italic;

//     div {
//       border: 0;
//     }
//   }

//   &.s_autre {
//     display: initial;

//     div {
//       display: inline-block;
//       border: 0;
//       flex-shrink: 1;
//       font-weight: initial;

//       &.key:after {
//         content: " : ";
//       }
//     }
//   }
// }

// .graph-wrapper {
//   .graph {
//     display: flex;

//     .barchart-bar {
//       height: 1.5rem;
//       padding: 20px 0px;
//       text-align: center;
//       // &:nth-child(odd) .label {
//       //     margin-top:-20px
//       // }
//       // &:nth-child(even) .label {
//       //     margin-top: 20px;
//       // }
//     }

//     .title {
//       font-size: 1.5rem;
//     }
//   }

//   .graph-labels {
//     display: flex;

//     .barchart-label {
//       flex-shrink: 0;
//       min-width: 1.3em;
//       text-align: center;
//     }
//   }

//   .graph-legend-wrapper {
//     padding: 10px 0px;

//     .graph-legend {
//       display: flex;
//       align-items: center;
//       margin-bottom: @small-margin;

//       .color {
//         flex-shrink: 0;
//         display: block;
//         margin-right: @margin;
//         width: 1rem;
//         height: 1rem;
//         border-radius: 1rem;
//         // stroke:1px;
//       }
//     }
//   }
// }

.ol-zoom {
  top: initial;
  left: initial;
  bottom: @margin;
  right: @margin;

  .ol-zoom-in,
  .ol-zoom-out {
    border-radius: 100%;
  }

  button {
    display: block;
    margin: 1px;
    color: black;
    background-color: white;
    border: 2px solid black;

    &:hover {
      border-color: @activeColor;
      color: @activeColor;
      background-color: white;
    }
  }

  .ol-zoom-in {
    margin-bottom: (@margin / 2);
  }
}

.ol-control {
  background-color: initial;
  padding: 0;

  &:hover {
    background-color: initial;
  }
}

// .the-sidebar {
//   #info {
//     .type-description {
//       font-style: italic;
//       font-size: 0.8rem;
//     }
//   }
// }

.sidebar {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  //   width: 15rem;
  width: 20rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  outline: @blueBorder;
  .scroll-mixin();

  .actions {
    border-top: @border;
    padding-top: 0.5rem;
    display: flex;
    align-items: center;

    button {
      margin-left: auto;
    }
  }
}

.legend__wrapper {
  .legendContent {
    > div {
      flex: 1;

      &.color {
        // border-right: @border;
        padding-right: (@margin / 2);
      }

      &.size {
        padding-left: (@margin / 2);
      }
    }
  }
}

.legend-element {
  display: flex;
  align-items: center;
  margin-bottom: @small-margin;
  margin-left: 2.5rem;
  width: fit-content;
  padding-right: 0.5rem;

  &:hover {
    // background-color: pink;
    // outline: @blueBorder;
    // border-radius: 0.5rem;
  }

  &.selected {
    background-color: #dcdcdc;
    outline: 5px solid #dcdcdc;
    border-radius: 0.5rem;
  }

  .color-circle {
    flex-shrink: 0;
    display: block;
    margin-right: 0.5rem;
    width: @legend-symbolW;
    height: @legend-symbolW;
    border-radius: @legend-symbolW;
    stroke: 1px;
  }
  .color-line {
    flex-shrink: 0;
    display: block;
    margin-right: @margin;
    width: @legend-symbolW;
    height: 0.5rem;
    stroke: 1px;
  }

  .size-wrapper {
    flex-shrink: 0;
    width: 32px;
    margin-right: @margin;
    display: flex;
    align-items: center;
    justify-content: center;

    .size {
      background-color: gray;
      flex-shrink: 0;
      display: block;
      border-radius: 100%;
      margin: auto;
    }
  }

  &.unknown {
    .size {
      position: relative;

      &:before {
        content: " ";
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background-color: white;
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
  }
  &.circles {
    position: relative;
    margin-left: 0.7rem
  }
  
  .picto-circles {
    background-image: url("assets/picto-cercle.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 25px;
    flex-shrink: 0;
  }
  .range {
    font-size: 0.7rem;
    padding-right: 0.5rem;
    padding-left: 2px;
    .min {
      position: relative;
      top: -3px;
    }
    .max {
      position:relative;
      top: 5px;
    }
  }
}


// button.description-trigger {
//   border: 0px;
//   margin: 0;
//   padding: 0;
//   width: 1.5rem;
//   height: 1.5rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: transparent;

//   &:hover {
//     color: @blue;
//     background-color: yellow;
//   }
// }

.legend.filter {
  margin-top: @margin;
  .legend-element {
    display: block;

    .legend-item-wrapper {
      display: flex;
      align-items: center;
    }

    .type-description {
      font-size: 0.8rem;
      padding-left: calc(@legend-symbolW + @margin*0.5);
    }
  }
  .label {
    cursor: pointer;
    font-weight: bold;

    &:hover {
      text-decoration: underline;

      &.unknown {
        text-decoration: none;
        cursor: auto;
      }
    }

    &.selected {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.legend.inactive {
  border-top: @border;
  padding-top: 0.5rem;
  .data-infos {
    font-size: @small-font;
    margin-bottom: 0;
    padding-left: 4.3rem;
  }
}

.data-infos {
  font-size: @small-font;
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  body {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 0.5rem;
    // border: @blueBorder;
  }

  #map,
  .sidebar,
  .credits {
    position: relative;
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
    // width: calc(~"100vw - 2px");
    width: 100%;
    outline: 0px;
  }

  .mobile-header {
    order: 1;
  }

  #map {
    order: 2;
    border: 0px;
  }
  .sidebar {
    order: 3;
  }
  .credits {
    order: 4;
  }

  //

  .mobile-header {
    display: block;
    padding-bottom: 0.5rem;
    h1 {
      border: 0px;
      padding: 0px;
    }
  }

  #map {
    // height: 75vh;
    border: @blueBorder;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .ol-viewport {
      height: 66vh !important;
      order: 1;
    }

    #feature-info {
      order: 2;
      position: relative;

      width: 100%;
      top: initial;
      right: initial;
      border: initial;
      border-top: @blueBorder;
      border-radius: initial;
    }
  }

  .sidebar {
    border: @blueBorder;
    border-top: 0px;
    border-radius: 0px;
    // border-bottom: @blueBorder;

    h1 {
      display: none;
    }
  }

  .lang-switch {
  }

  #geocoder {
    left: 0.5rem;
    width: calc(~"100% - 1rem");

    .tool.geocoder {
      width: 100%;
      max-width: initial;

      .tool-body.adress {
        width: 100%;
        max-width: initial;

        input {
          width: 100%;
        }
      }
    }
  }

  .legend.color {
    margin: 0 !important;
    padding: 0;
    // padding-bottom: 0.5rem;

    columns: 2;
    gap: 0.5rem;

    .legend-element {
      margin: initial;
      margin-bottom: 0.5rem;
    }
  }

  #feature-info {
    // width: calc(~"100% - 1rem");

    .count {
      font-size: 1rem;
    }
  }

  .credits {
    padding: 0.5rem;
  }
}

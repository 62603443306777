@font-face {
  font-family: "Calibri";
  src: url("assets/font/Calibri-LightItalic.eot");
  src: local("Calibri Light Italic"), local("Calibri-LightItalic"),
    url("assets/font/Calibri-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("assets/font/Calibri-LightItalic.woff2") format("woff2"),
    url("assets/font/Calibri-LightItalic.woff") format("woff"),
    url("assets/font/Calibri-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/font/Calibri-Italic.eot");
  src: local("Calibri Italic"), local("Calibri-Italic"),
    url("assets/font/Calibri-Italic.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Calibri-Italic.woff2") format("woff2"),
    url("assets/font/Calibri-Italic.woff") format("woff"),
    url("assets/font/Calibri-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/font/Calibri-Light.eot");
  src: local("Calibri Light"), local("Calibri-Light"),
    url("assets/font/Calibri-Light.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Calibri-Light.woff2") format("woff2"),
    url("assets/font/Calibri-Light.woff") format("woff"),
    url("assets/font/Calibri-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/font/Calibri-Bold.eot");
  src: local("Calibri Bold"), local("Calibri-Bold"),
    url("assets/font/Calibri-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Calibri-Bold.woff2") format("woff2"),
    url("assets/font/Calibri-Bold.woff") format("woff"),
    url("assets/font/Calibri-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/font/Calibri.eot");
  src: local("Calibri"),
    url("assets/font/Calibri.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Calibri.woff2") format("woff2"),
    url("assets/font/Calibri.woff") format("woff"),
    url("assets/font/Calibri.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/font/Calibri-BoldItalic.eot");
  src: local("Calibri Bold Italic"), local("Calibri-BoldItalic"),
    url("assets/font/Calibri-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Calibri-BoldItalic.woff2") format("woff2"),
    url("assets/font/Calibri-BoldItalic.woff") format("woff"),
    url("assets/font/Calibri-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

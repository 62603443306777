form {
  display: flex;
  flex-direction: column;
  //   margin: 20px;
  label {
    padding-top: 15px;
  }
  input,
  select,
  textarea {
    display: block;
    padding: 0.2rem;
  }
  input[type="submit"] {
    margin-top: 20px;
    width: 5rem;
  }

  input[type="number"] {
    width: 4rem;
  }

  .header {
    // position: sticky;
    // top: 0;
    background-color: @yellow;
    padding: @margin;
  }

  .footer {
    position: sticky;
    bottom: 0;
    background-color: @yellow;
    padding: @margin;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: fit-content;
      border: @blueBorder;
      background-color: @blue;
      color: white;
      padding: 0.5rem;
      margin:0.5rem;

      &:hover {
        color: @blue;
        background-color: @white;
      }
    }
  }

  table {
    padding: 0 @margin;
    tr {
      height: 3rem;
      padding: 0.25rem;
    }
    tr:nth-child(even) {
      background-color: @greyLight;
    }
    th {
      text-align: left;
      position: sticky;
      top: 0;
      background-color: white;
      border-bottom: @border;
    }
    td {
      padding: 0.5rem;
    }
  }
}
